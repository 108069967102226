@layer modules, ui, base;
@layer base {
  .MediaVideo_wrapper__LPErT {
  position: relative;
  text-align: center;
  cursor: pointer;
}

.MediaVideo_icon__KOuBw {
  position: absolute;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.MediaVideo_thumbnailWrapper__BzZSd {
  position: relative;
  margin: 0;
}

.MediaVideo_thumbnail__EeEbi {
  display: block;
  width: 100%;
  height: auto;
}

.MediaVideo_videoWrapper__DiOJQ {
  overflow: hidden;
}

.MediaVideo_video__s2DSO {
  position: absolute;
  inset: 0;
}

}
@layer base {
  .RichText_layout__xnQ4w {
  max-width: 100vw;
}

.RichText_layout--withNumber__XhWdN {
  padding-top: 150px;
  padding-bottom: 0;
}

.RichText_image__1OU18 {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.RichText_buttonsWrapper__PUWwj {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--spacer-200);
}

.RichText_button__FArBS {
  border-color: var(--color-black);
  background-color: var(--color-black);
  color: var(--color-white);
}

.RichText_textWrapper__cw85j {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-400);
}

.RichText_text__gtkCZ p,
.RichText_testimonyText__yxGbm p {
  margin: 0;
}

.RichText_text__gtkCZ p + p {
  margin-top: var(--spacer-300);
}

.RichText_text__gtkCZ img {
  max-width: 100%;
}

.RichText_text__gtkCZ ol {
  list-style: none;
}

.RichText_text__gtkCZ ol li {
  position: relative;
  padding-bottom: var(--spacer-300);
}

.RichText_text__gtkCZ ol li::before {
  content: url('data:image/svg+xml;charset=UTF-8,<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM16.2071 10.2071C16.5976 9.81658 16.5976 9.18342 16.2071 8.79289C15.8166 8.40237 15.1834 8.40237 14.7929 8.79289L10.5 13.0858L9.20711 11.7929C8.81658 11.4024 8.18342 11.4024 7.79289 11.7929C7.40237 12.1834 7.40237 12.8166 7.79289 13.2071L9.79289 15.2071C10.1834 15.5976 10.8166 15.5976 11.2071 15.2071L16.2071 10.2071Z" /></svg>');
  display: block;
  position: absolute;
  left: calc(var(--spacer-400) * -1);
  width: 24px;
  height: 24px;
}

.RichText_text__gtkCZ ul {
  padding-left: var(--spacer-200);
}

.RichText_text__gtkCZ ul li {
  padding-bottom: var(--spacer-50);
}

.RichText_text__gtkCZ a,
.RichText_text__gtkCZ a:visited {
  color: var(--color-black);
}

.RichText_text__gtkCZ a:hover {
  color: var(--color-purple--500);
}

.RichText_text__gtkCZ code {
  display: inline-block;
  position: relative;
  top: calc(var(--minorBody) / 4 * -1);
  padding: 2px 12px;
  border-radius: 30px;
  background-color: var(--color-purple--500);
  color: var(--color-white);
  font-family: var(--fontFamily);
  font-size: var(--minorBody);
  line-height: var(--minorBodyLineHeight);
}

.RichText_text__gtkCZ h1,
.RichText_text__gtkCZ h2,
.RichText_text__gtkCZ h3,
.RichText_text__gtkCZ h4,
.RichText_text__gtkCZ h5,
.RichText_text__gtkCZ h6 {
  font-weight: var(--bold);
}

.RichText_text__gtkCZ h1 {
  margin: var(--spacer-400) 0;
  font-size: var(--headingSmall);
  line-height: var(--headingSmallLineHeight);
}

.RichText_text__gtkCZ h2 {
  margin: var(--spacer-300) 0;
  font-size: var(--subhead);
  line-height: var(--subheadLineHeight);
}

.RichText_text__gtkCZ h3 {
  margin: var(--spacer-200) 0;
  font-size: var(--subheadSmall);
  line-height: var(--subheadSmallLineHeight);
}

.RichText_text__gtkCZ h4,
.RichText_text__gtkCZ h5,
.RichText_text__gtkCZ h6 {
  margin: var(--spacer-150) 0;
  font-size: var(--normalBody);
  line-height: var(--normalBodyLineHeight);
}

.RichText_columnText__EX87c {
  margin-bottom: var(--spacer-300);
}

.RichText_title__ENy1t {
  position: relative;
  margin-bottom: var(--spacer-400);
}

.RichText_title__ENy1t::before {
  content: attr(data-number);
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: -165px;
  left: -70px;
  background: linear-gradient(-8deg, rgb(157 44 255 / 100%) 11%, rgb(0 210 0 / 100%) 100%);
  -webkit-text-stroke: 3px transparent;
  -webkit-background-clip: text;
          background-clip: text;
  color: var(--color-white);
  font-size: 250px;
  font-weight: var(--black);
  line-height: 200px;
}

.RichText_testimony__oZ7nA {
  position: relative;
  margin-top: var(--spacer-200);
  padding: var(--spacer-400);
  border-radius: var(--radius-200);
  background-color: var(--color-grey--025);
}

.RichText_testimony__oZ7nA::after {
  content: "";
  position: absolute;
  right: var(--spacer-50);
  bottom: var(--spacer-200);
  width: 138px;
  height: 133px;
  background-image: var(--backgroundImage);
  background-size: cover;
}

.RichText_testimonyText__yxGbm {
  font-style: italic;
}

.RichText_testimonyText__yxGbm + .RichText_testimonyText__yxGbm {
  margin-top: var(--spacer-100);
}

.RichText_columnMedia__Nqygh {
  position: relative;
}

/* For multiple image */
.RichText_imageWrapper--multiple__m2PkF::before {
  content: "";
  display: block;
  position: absolute;
  top: -8px;
  left: 8px;
  width: 100%;
  height: 100%;
  border-radius: var(--radius-200);
  background: linear-gradient(-8deg, rgb(157 44 255) 11%, rgb(0 210 0) 100%);
}

.RichText_imageWrapper--multiple__m2PkF .RichText_image__1OU18 {
  position: relative;
  overflow: hidden;
}

.RichText_imageWrapper--multiple__m2PkF,
.RichText_imageWrapper--multiple__m2PkF .RichText_image__1OU18 {
  max-width: 50vw;
  height: auto;
  border-radius: var(--radius-200);
}

.RichText_imageWrapper--multiple__m2PkF:nth-child(1) {
  position: absolute;
  top: var(--spacer-50);
  left: var(--Layout-column-margin-size);
  transform: rotate(-6deg);
}

.RichText_imageWrapper--multiple__m2PkF:nth-child(2) {
  position: relative;
  top: 30px;
  left: calc(50vw - var(--spacer-600));
}

/* Hide image if there are more than 2 */
.RichText_imageWrapper__PDUgz:nth-child(n + 3) {
  display: none;
}

@media (max-width: 47.999rem) {
  .RichText_layout--withTwoImage__ID7BB .RichText_columnText__EX87c {
    display: flex;
    order: 2;
  }

  .RichText_layout--withTwoImage__ID7BB .RichText_columnMedia__Nqygh {
    display: flex;
    order: 1;
    padding-bottom: var(--spacer-400);
  }

  .RichText_layout--withMedia__lYZzY:only-child .RichText_columnMedia__Nqygh {
    display: none;
  }
}

@media (min-width: 48rem) {
  .RichText_layout__xnQ4w {
    flex-direction: row;
    overflow: hidden;
  }

  /* FOR LEGACY: start */
  .RichText_layout--withMedia__lYZzY,
  .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY {
    flex-direction: row-reverse;
  }

  .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY,
  .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY,
  .RichText_layout--withTwoImage__ID7BB {
    flex-direction: row;
  }

  .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY .RichText_columnText__EX87c,
  .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY .RichText_columnText__EX87c,
  .RichText_layout--withTwoImage__ID7BB .RichText_columnText__EX87c {
    margin-right: var(--Layout-column-margin-size);
  }

  .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY .RichText_columnMedia__Nqygh,
  .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY .RichText_columnMedia__Nqygh,
  .RichText_layout--withTwoImage__ID7BB .RichText_columnMedia__Nqygh {
    margin-left: var(--Layout-column-margin-size);
  }
  /* FOR LEGACY: end */

  .RichText_layout--withTextOnRight___t9kC {
    flex-direction: row-reverse !important;
  }

  .RichText_layout--withTextOnRight___t9kC .RichText_columnText__EX87c {
    margin-right: var(--Layout-column-margin-size) !important;
  }

  .RichText_layout--withTextOnRight___t9kC .RichText_columnMedia__Nqygh {
    margin-left: var(--Layout-column-margin-size) !important;
  }

  .RichText_layout--withTextOnRight___t9kC .RichText_image__1OU18 {
    right: 0 !important;
    left: inherit !important;
  }

  .RichText_layout--withTextOnLeft__RgLmH {
    flex-direction: row !important;
  }

  .RichText_layout--withTextOnLeft__RgLmH .RichText_columnText__EX87c {
    margin-right: var(--Layout-column-margin-size) !important;
  }

  .RichText_layout--withTextOnLeft__RgLmH .RichText_columnMedia__Nqygh {
    margin-left: var(--Layout-column-margin-size) !important;
  }

  .RichText_layout--withTextOnLeft__RgLmH .RichText_image__1OU18 {
    right: inherit !important;
    left: 0 !important;
  }

  .RichText_title__ENy1t {
    font-size: var(--heading);
    line-height: var(--headingLineHeight);
  }

  .RichText_columnMedia__Nqygh {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .RichText_imageWrapper__PDUgz:not(.RichText_imageWrapper--multiple__m2PkF) {
    display: inline-block;
    position: relative;
    width: 100%;
    height: var(--heightImage);
    max-height: 600px;
  }

  .RichText_imageWrapper__PDUgz:not(.RichText_imageWrapper--multiple__m2PkF) .RichText_image__1OU18 {
    position: absolute;
    top: 0;
    width: auto;
    max-width: inherit;
    height: inherit;
    max-height: 600px;
  }

  /* FOR LEGACY: start */
  .RichText_layout--withMedia__lYZzY .RichText_image__1OU18 {
    right: 0;
  }

  .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY .RichText_image__1OU18,
  .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY .RichText_image__1OU18 {
    right: inherit;
    left: 0;
  }

  .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY + .RichText_layout--withMedia__lYZzY .RichText_image__1OU18 {
    right: 0;
    left: inherit;
  }
  /* FOR LEGACY: end */

  .RichText_imageWrapper--multiple__m2PkF,
  .RichText_imageWrapper--multiple__m2PkF .RichText_image__1OU18 {
    max-width: var(--widthImage);
  }

  .RichText_imageWrapper--multiple__m2PkF {
    width: var(--widthImage);
    height: var(--heightImage);
  }

  .RichText_imageWrapper--multiple__m2PkF:nth-child(1) {
    top: var(--spacer-150);
    left: var(--spacer-300);
  }

  .RichText_imageWrapper--multiple__m2PkF:nth-child(2) {
    position: absolute;
    top: var(--heightImage);
    left: 0;
    transform: rotate(6deg);
  }

  .RichText_columnText__EX87c {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 0;
  }

  /* if there is an image, the text must be on the same column */
  .RichText_layout__xnQ4w:not(.RichText_layout--withMedia__lYZzY) .RichText_textWrapper__cw85j {
    flex-direction: row;
  }

  .RichText_text__gtkCZ,
  .RichText_testimonyText__yxGbm {
    font-size: var(--normalBody);
    line-height: var(--normalBodyLineHeight);
  }

  .RichText_textWrapper__cw85j .RichText_text__gtkCZ,
  .RichText_textWrapper__cw85j .RichText_textContent__KRrzf {
    flex: 1;
  }

  .RichText_text__gtkCZ ol li::before {
    left: calc(var(--spacer-500) * -1);
    width: 32px;
    height: 32px;
  }

  .RichText_text__gtkCZ :first-child {
    margin-top: 0;
  }

  .RichText_text__gtkCZ h1 {
    font-size: var(--heading);
    line-height: var(--headingLineHeight);
  }

  .RichText_text__gtkCZ h2 {
    font-size: var(--headingSmall);
    line-height: var(--headingSmallLineHeight);
  }

  .RichText_text__gtkCZ h3 {
    font-size: var(--subhead);
    line-height: var(--subheadLineHeight);
  }

  .RichText_text__gtkCZ h4 {
    font-size: var(--editoBody);
    line-height: var(--editoBodyLineHeight);
  }

  .RichText_text__gtkCZ h5,
  .RichText_text__gtkCZ h6 {
    font-size: var(--subheadSmall);
    line-height: var(--subheadSmallyLineHeight);
  }

  /* when Richtext is alone */
  .RichText_layout--withMedia__lYZzY:only-child {
    padding-top: 0;
    padding-bottom: 0;
  }

  .RichText_layout--withMedia__lYZzY:only-child .RichText_columnText__EX87c {
    padding: var(--spacer-800) 0;
  }

  .RichText_layout--withMedia__lYZzY:only-child .RichText_columnMedia__Nqygh {
    --layout-column-number: 6 !important;
    justify-content: stretch;
    min-height: 75vh;
  }

  .RichText_layout--withMedia__lYZzY:only-child .RichText_imageWrapper__PDUgz {
    height: 100%;
    max-height: initial;
  }

  .RichText_layout--withMedia__lYZzY:only-child .RichText_image__1OU18 {
    -o-object-fit: cover;
       object-fit: cover;
    min-width: 100%;
    min-height: 100%;
  }
}

@media (min-width: 64rem) {
  .RichText_imageWrapper--multiple__m2PkF:nth-child(2) {
    top: calc(var(--heightImage) * (2 / 3));
  }
}

@media (min-width: 80rem) {
  .RichText_imageWrapper--multiple__m2PkF:nth-child(2) {
    top: 40px;
    left: calc(var(--widthImage) - var(--spacer-600));
    transform: initial;
  }
}

}
