@layer base {
  .wrapper {
  position: relative;
  text-align: center;
  cursor: pointer;
}

.icon {
  position: absolute;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.thumbnailWrapper {
  position: relative;
  margin: 0;
}

.thumbnail {
  display: block;
  width: 100%;
  height: auto;
}

.videoWrapper {
  overflow: hidden;
}

.video {
  position: absolute;
  inset: 0;
}

}